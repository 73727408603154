<template>
  <div class="vid-box">
    <!-- <video
      ref="videoPlayer"
      class="video"
      autoplay
      preload
      loop
      muted
      src="../../assets/viedo/2880_1672-2.mp4"
    >
    </video> -->
    <!-- Slider main container -->
    <div class="swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <img src="@/assets/img/01.jpg">

        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/02.jpg">

        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/03.jpg">

        </div>
      </div>
      <div class="bg">
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>
    </div>
    <div class="footer-bottom">
      <router-link
        tag="span"
        class="msg"
        to="message"
      >公司资讯</router-link>
      <span class="bot1text">
        <a
          class="zggs"
          target="_blank"
          href=" https://beian.miit.gov.cn/"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-right:80px"
        >
          <img
            class="img1"
            src="../../assets/img/zggs.png"
            style="float:left;"
          >
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;">沪ICP备19004421号-2</p>
        </a>
        <a
          class="gabeian"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007104"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
        ><img
            src="../../assets/img/gabeian.png"
            style="float:left;"
          />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;">沪公网安备 31010102007104号</p>
        </a>
      </span>
      <span class="bot2text">
        <a href="">
          © <img
            class="img2"
            src="../../assets/img/logo.png"
          >
        </a>
      </span>
      <!-- jgmessage -->
      <!-- <router-link
        tag="span"
        class="bot3text"
        to="jgmessage"
      >上海橘宜化妆品有限公司</router-link> -->
      <span class="bot3text">
        上海橘宜化妆品有限公司
      </span>
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

export default {
  data() {
    return {
      vidSrc: ''
    }
  },
  mounted() {

    const swiper = new Swiper('.swiper-container', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'my-bullet',
        bulletActiveClass: 'my-bullet-active',
      },
    });
  },
  methods: {
    toPlays() {
      this.$refs.videoPlayer.paused === true ? this.$refs.videoPlayer.play() : this.$refs.videoPlayer.pause();
    }
  }
}
</script>

<style lang="scss" scoped>
.vid-box {
  width: 100%;
  height: calc(100vh - 7vh);

  .video {
    // height: 100%;
    width: 100%;
    // object-fit: cover;
    // object-position: center;
  }
  .footer-bottom {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
    .bot1text {
      margin-right: 80px;
      a {
        font-size: 12px;
        vertical-align: middle;
        // margin-right: 30px;
      }
      .zggs {
        img {
          height: 20px;
          width: 20px;
          margin-left: 5px;
        }
      }
      .gabeian {
        img {
        }
      }
      // .img1 {
      //   vertical-align: middle;
      //   width: 12px;
      // }
    }
    .bot2text {
      a {
        font-size: 16px;
        vertical-align: middle;

        .img2 {
          vertical-align: middle;
          height: 45px;
        }
      }
    }
  }
}

.swiper-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 51rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.bot3text {
  margin-left: 80px;
  vertical-align: middle;
  // cursor: pointer;
}
.msg {
  margin-right: 80px;
  cursor: pointer;
}
</style>