import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import home from '../views/home/index.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/home/index'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/index'),
  },
  /* 二层级-系列以及分类产品 开始 */
  // 丝绸系列
  {
    path: '/sichou',
    name: 'sichou',
    component: () => import(/* webpackChunkName: "sichou" */ '@/views/series/sichou/index'),
  },
  // 琥珀系列
  {
    path: '/hupo',
    name: 'hupo',
    component: () => import(/* webpackChunkName: "hupo" */ '@/views/series/hupo/index'),
  },
  // Y2K系列
  {
    path: '/Y2K',
    name: 'Y2K',
    component: () => import(/* webpackChunkName: "Y2K" */ '@/views/series/y2k/index'),
  },
  {
    path: '/beike',
    name: 'beike',
    component: () => import(/* webpackChunkName: "beike" */ '@/views/series/beike/index'),
  },
  {
    path: '/fubo',
    name: 'fubo',
    component: () => import(/* webpackChunkName: "fubo" */ '@/views/series/fubo/index'),
  },

  // 口红
  {
    path: '/lips',
    name: 'lips',
    component: () => import(/* webpackChunkName: "chunbu" */ '@/views/lips/chunbu/index'),
  },
  {
    path: '/gloss',
    name: 'gloss',
    component: () => import(/* webpackChunkName: "gloss" */ '@/views/lips/gloss/index'),
  },
  {
    path: '/stick',
    name: 'stick',
    component: () => import(/* webpackChunkName: "stick" */ '@/views/lips/stick/index'),
  },

  // 眼部
  {
    path: '/yanbu',
    name: 'yanbu',
    component: () => import(/* webpackChunkName: "yanbu" */ '@/views/eyes/yanbu/index'),
  },
  {
    path: '/shadow',
    name: 'shadow',
    component: () => import(/* webpackChunkName: "shadow" */ '@/views/eyes/shadow/index'),
  },
  {
    path: '/liner',
    name: 'liner',
    component: () => import(/* webpackChunkName: "liner" */ '@/views/eyes/liner/index'),
  },

  // 颊彩
  {
    path: '/cheek',
    name: 'cheek',
    component: () => import(/* webpackChunkName: "jiacai" */ '@/views/cheek/jiacai/index'),
  },
  {
    path: '/blusher',
    name: 'blusher',
    component: () => import(/* webpackChunkName: "blusher" */ '@/views/cheek/blusher/index'),
  },
  {
    path: '/highlight',
    name: 'highlight',
    component: () => import(/* webpackChunkName: "highlight" */ '@/views/cheek/highlight/index'),
  },

  // 底妆
  {
    path: '/fandation',
    name: 'fandation',
    component: () => import(/* webpackChunkName: "fandation" */ '@/views/fandation/dizhuang/index'),
  },
  {
    path: '/concealer',
    name: 'concealer',
    component: () => import(/* webpackChunkName: "concealer" */ '@/views/fandation/concealer/index'),
  },
  {
    path: '/puffcake',
    name: 'puffcake',
    component: () => import(/* webpackChunkName: "puffcake" */ '@/views/fandation/puffcake/index'),
  },

  // 其他
  {
    path: '/qita',
    name: 'qita',
    component: () => import(/* webpackChunkName: "qita" */ '@/views/series/qita/index'),
  },
  /* 二层级-系列以及分类产品 结束 */
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '@/views/detail/index'),
    meta: {
      TabbarShow: false // 不需要显示
    }
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import(/* webpackChunkName: "brand" */ '@/views/brand/index'),
  },
  {
    path: '/channels',
    name: 'channels',
    component: () => import(/* webpackChunkName: "channels" */ '@/views/channels/index'),
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import(/* webpackChunkName: "contactus" */ '@/views/contactus/index'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "searchStr" */ '@/views/searchStr/index'),

  },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "searchStr" */ '@/views/message.vue'),
  },
  {
    path: '/jgmessage',
    name: 'jgmessage',
    component: () => import(/* webpackChunkName: "searchStr" */ '@/views/jgmessage.vue'),
  },
  {
    path: '/xiachan',
    name: 'xiachan',
    component: () => import(/* webpackChunkName: "searchStr" */ '@/views/series/xiachan/index'),
  },
  {
    path: '/youran',
    name: 'youran',
    component: () => import(/* webpackChunkName: "searchStr" */ '@/views/series/youran/index'),
  },
  {
    path: '/vintage',
    name: 'vintage',
    component: () => import(/* webpackChunkName: "searchStr" */ '@/views/series/vintage/index'),
  }
]

const router = new VueRouter({
  routes,
  //判断路由跳转之后是否是发生滚动 如果发生了滚动返回到最上面
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
