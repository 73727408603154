import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import '@/style/index.css'
import '@/styles/index.scss' // global css
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VideoPlayer from 'vue-video-player'
import "@/utils/element"
import i18n from './lang'
import 'lib-flexible/flexible'
import VueLazyload from 'vue-lazyload'
import Base64 from './utils/base64'

Vue.prototype.$Base64 = Base64;

Vue.use(VueLazyload, {
  loading: require('@/assets/loading.png'),//加载中图片，一定要有，不然会一直重复加载占位图
  // error: require('img/error.png')  //加载失败图片
});
Vue.config.productionTip = false
Vue.prototype.$video = Video
Vue.use(VideoPlayer);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
