<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view v-if="isReloadActive" />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isLang: false,
      isReloadActive: true
    }
  },
  components: {
    Navbar
  },
  methods: {
    reload() {
      this.isReloadActive = false
      this.$nextTick(() => {
        this.isReloadActive = true
      })
    }
  }
}
</script>

<style lang="scss">
</style>
