export default {
  route: {
    home: '首页'
  },
  home: {
    ENlang: 'Shop in EN',
    CNlang: 'Shop in CN',
    about: '关于我们',
    product: '产品'
  }
}