export default {
  route: {
    home: 'Home'
  },
  home: {
    ENlang: 'Shop in EN',
    CNlang: 'Shop in CN',
    about: 'About Us',
    product: 'Product'
  }
}
