// 中英文
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh'
import en from './en'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh',    // 语言标识
  // 通过切换locale的值来实现语言切换
  messages: {
    zh,   // 中文语言包
    en    // 英文语言包
  }
})



export default i18n