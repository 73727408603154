<template>
  <div class="box">
    <div
      class="nav-bar"
      v-show="isShow"
    >
      <div
        class="nav-logo"
        @click="routerHome"
      >
        <img
          src="../../src/assets/img/logo.png"
          alt=""
        >
      </div>
      <ul class="product">
        <li class="product-drop">
          <router-link
            class="about"
            to="/about"
          >{{ $t('home.product') }}</router-link>
          <div class="product-drop-list">
            <ul>
              <h4>
                <router-link
                  class=""
                  to="/about"
                >所有产品</router-link>
              </h4>
            </ul>
            <ul>
              <router-link
                class=""
                to=""
                tag="h4"
                lipgloss
              >系列产品</router-link>
              <router-link
                class=""
                to="/hupo"
                tag="li"
              >琥珀系列</router-link>
              <router-link
                class=""
                to="/Y2K"
                tag="li"
              >Y2K系列</router-link>
              <router-link
                class=""
                to="/beike"
                tag="li"
              >贝壳系列</router-link>
              <router-link
                class=""
                to="/sichou"
                tag="li"
              >丝绸系列</router-link>
              <router-link
                class=""
                to="/fubo"
                tag="li"
              >浮波系列</router-link>
              <router-link
                class=""
                to="/xiachan"
                tag="li"
              >夏禅系列</router-link>
              <router-link
                class=""
                to="/youran"
                tag="li"
              >游染系列</router-link>
              <router-link
                class=""
                to="/vintage"
                tag="li"
              >复古系列</router-link>
            </ul>
            <ul>
              <router-link
                class=""
                to="/lips"
                tag="h4"
                lipgloss
              >唇部</router-link>
              <router-link
                class=""
                to="/gloss"
                tag="li"
              >唇釉</router-link>
              <router-link
                class=""
                to="/stick"
                tag="li"
              >口红</router-link>
              <li>其他</li>
            </ul>
            <ul>
              <router-link
                class=""
                to="/yanbu"
                tag="h4"
              >眼部</router-link>
              <router-link
                class=""
                to="/shadow"
                tag="li"
              >眼影</router-link>
              <router-link
                class=""
                to="/liner"
                tag="li"
              >眼线</router-link>
              <li>眉部</li>
            </ul>
            <ul>
              <router-link
                class=""
                to="/cheek"
                tag="h4"
              >颊彩</router-link>
              <router-link
                class=""
                to="/blusher"
                tag="li"
              >腮红</router-link>
              <router-link
                class=""
                to="/highlight"
                tag="li"
              >高光</router-link>
            </ul>
            <ul>
              <router-link
                class=""
                to="/fandation"
                tag="h4"
              >底妆</router-link>
              <router-link
                class=""
                to=""
                tag="li"
              >粉底</router-link>
              <router-link
                class=""
                to="/puffcake"
                tag="li"
              >粉饼</router-link>
              <router-link
                class=""
                to="/concealer"
                tag="li"
              >遮瑕</router-link>
              <li>修容</li>
            </ul>
            <ul>
              <!-- <router-link
                class=""
                to="/qita"
                tag="h4"
              >其他产品</router-link> -->
              <h4>其他产品</h4>
              <li>洁颜油</li>
              <li>化妆刷</li>
              <li>美妆蛋</li>
              <li>睫毛夹</li>
            </ul>
          </div>
        </li>
        <li class="about-us">
          <router-link
            class="about"
            to="/brand"
          >{{$t('home.about')}}</router-link>
          <div class="about-us-drop">
            <ul class="about-us-drop-list">
              <li>
                <router-link
                  class="about-us-item"
                  to="brand"
                >品牌故事</router-link>
              </li>
              <li>
                <router-link
                  class="about-us-item"
                  to="channels"
                >官方购买渠道</router-link>
              </li>
              <li>
                <router-link
                  class="about-us-item"
                  to="contactus"
                >联系我们</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="lang">
          <span class="about">{{$t('home.CNlang')}}</span>
          <div class="lang-droplist">
            <span @click="changeLang">{{$t('home.ENlang')}}</span>
          </div>
        </li>
      </ul>
      <div class="tab-bar">
        <div class="search">
          <input
            :style="{width:iptWidth+'%'}"
            class="ipt"
            ref="ipt"
            type="text"
            v-model="searchStr"
          >
          <img
            class="search-btn"
            @click="handleActive"
            src="../assets/img/souso.png"
            alt=""
          >
        </div>
        <a href="https://weibo.com/p/1006067083527002/home?from=page_100606&mod=TAB#place">
          <img src="../assets/img/weibo.png">
        </a>
        <a href="https://www.instagram.com/joocyee_official/">
          <img src="../assets/img/INS.png">
        </a>
        <a href="https://www.xiaohongshu.com/user/profile/5bb0314de2b1da0001ca4f57?xhsshare=CopyLink&appuid=5bb0314de2b1da0001ca4f57&apptime=1627026364">
          <img src="../assets/img/xiaohongshu.png">
        </a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  data() {
    return {
      iptWidth: 0,
      searchStr: '',
      isShow: true,
      i: 0,
    }
  },
  created() {
    window.addEventListener('click', e => {
      if (e.target.className !== 'ipt' && e.target.className !== 'search-btn') {
        this.iptWidth = 0
        this.searchStr = ''
      }
    })
  },
  mounted() {
    window.addEventListener('scroll', this.isScroll, true)
    var lett = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        lett.handleActive();
      }
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.isScroll, true)
  },
  methods: {
    togo(paTh) {
      this.$router.path({ path: paTh })
    },
    isScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if (scroll < 0 || scrollTop == 0 || scrollTop < 30) {
        // console.log('up')
        //添加你想要的事件
        this.isShow = true
      } else {
        //添加你想要的事件
        // console.log('down')
        this.isShow = false
      }
    },
    routerHome() {
      this.$router.push({ path: '/' })
    },
    handleBlur() {
      this.iptWidth = 0
    },
    changeLang() {
      if (this.$i18n.locale === 'zh') {
        this.$root.$i18n.locale = 'en'
      } else {
        this.$root.$i18n.locale = 'zh'
      }
    },
    handleActive() {
      if (!this.iptWidth) {
        this.iptWidth = 100
        this.$refs.ipt.focus()
      } else {
        if (this.searchStr.trim() !== '') {
          const keyword = this.searchStr
          this.$router.push({ name: 'search', query: { keyword } })
          this.reload()
        } else {
          this.iptWidth = 0
        }
      }
    },
  }


}
</script>

<style lang="scss" scoped>
.box {
  height: 7vh;
  width: 100%;
}
.nav-bar {
  width: 100%;
  height: 7vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  .nav-logo {
    margin-left: 50px;
    width: 130px;
    img {
      width: 130px;
      cursor: pointer;
    }
  }
  .act {
    color: #9195a3;
  }
  .product {
    position: absolute;
    height: 7vh;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
  .product > li {
    padding: 15px 0px;
    color: #000;
    float: left;
    .about {
      display: block;
      // line-height: 70px;
      font-size: 0.625rem;
      cursor: pointer;
      padding: 0 30px;
      margin: 0 10px;
    }
    .about:hover {
      color: #9195a3;
    }
    .product-drop:hover .about {
      color: #9195a3;
    }
  }
  .product-drop-list {
    width: 646px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 7vh;
    right: 0;
    margin: 0 auto;
    background: #fff;
    padding: 30px 70px;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    // transform: all 1s;
    transition: visibility 0.15s;
    z-index: 999;
    ul {
      float: left;
      h4 {
        cursor: pointer;
        font-weight: 500;
        color: #000;
        margin-bottom: 12px;
        font-size: 12px;
      }
      li {
        font-size: 12px;
        color: #9195a3;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
  .product-drop:hover .product-drop-list,
  .product-drop-list:hover {
    visibility: visible;
  }
  .about-us-drop {
    position: absolute;
    left: 0px;
    top: 7vh;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    background: #fff;
    padding: 30px 60px 42px 60px;
    visibility: hidden;
    transition: visibility 0.15s;
    z-index: 100;
    .about-us-drop-list {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      background: #fff;
      z-index: 100;
      // color: #9195a3;
      .li {
        .about-us-item {
          // font-size: 12px;
        }
      }
    }
  }
  .about-us:hover .about-us-drop,
  .about-us-drop:hover {
    visibility: visible;
  }
  .lang {
    // position: relative;
    .lang-droplist {
      padding: 15px 39px;
      background: #fff;
      position: absolute;
      top: 7vh;
      visibility: hidden;
      transition: visibility 0.15s;
      span {
        cursor: pointer;
      }
    }
  }
  // .lang:hover .lang-droplist,
  // .lang-droplist:hover {
  //   visibility: visible;
  // }
  .tab-bar {
    display: flex;
    align-items: center;
    margin-right: 50px;
    a {
      img {
        margin: 0 8px;
        width: 24px;
        cursor: pointer;
      }
    }
    a:nth-of-type(3) {
      img {
        width: 35px;
      }
    }
    .search {
      position: relative;
      display: flex;
      align-items: center;
      width: 125px;
      height: 20px;
      margin-right: 30px;
      .ipt {
        position: absolute;
        top: 0;
        left: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid #000;
        padding: 3px 0;
        transition: 0.4s;
        transform: translateX(-100%);
        box-sizing: border-box;
      }
      img {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 0;
        margin: 0;
        cursor: pointer;
      }
    }
  }
}
</style>